@import "../../styles/theme.scss";

.SurveyInvalid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: $secondaryColor;
  text-align: center;

  @media (max-width: $sm) {
    height: 100dvh !important;
  }

  &__title {
    margin-bottom: 2.5rem;
  }

  &__info {
    white-space: pre-wrap;
    margin-bottom: 1rem;
  }

  &__img {
    max-height: 350px;

    @media (max-width: $sm) {
      max-height: 250px;
    }
  }
}

.TrainingModuleOverview {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    position: sticky;
    top: 0;
    z-index: 98;
    width: 100%;
    height: 100px;
    &__thumbnail {
      width: inherit;
      height: inherit;
    }
  }
}

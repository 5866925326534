@import "../styles/theme.scss";

$pageTopPadding: 1rem;

.SurveyPreviewPage {
  background-color: $white;
  position: relative;
  width: 100%;

  &__page {
    height: calc(100vh - $headerHeight - $pageTopPadding);
    overflow-y: auto;
    padding: $pageTopPadding 2rem 0 2rem;
    margin: auto;

    @media (max-width: $sm) {
      padding: 0.5rem 0.5rem 0 0.5rem;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey500;
      border-radius: 4px;
    }
  }

  &__progress {
    position: absolute;
    right: 0;
    top: calc($headerHeight + $pageTopPadding);
    @media (max-width: $sm) {
      display: none;
    }
  }
}

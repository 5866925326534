@import "../styles/theme.scss";

.DashboardPage {
  &__title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__widget__soon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: $comingsoonWidgetHeight;
    padding: 2rem;
    text-align: center;

    &__title {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 1rem;
    }
    &__info {
      font-weight: 300;
      line-height: 16px;
      font-size: 12px;
    }
    img {
      padding-top: 1rem;
      max-height: 175px;
    }
  }

  &__widget {
    width: 100%;
  }
}

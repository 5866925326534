@import "../../styles/theme.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    box-shadow: 0px 0px 0px 0px rgba(139, 139, 139, 0.25);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    box-shadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);
  }
}
@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  50% {
    transform: translate(0, 0px) rotate(30deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
.SignUp {
  opacity: 0;
  box-shadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);
  text-align: center;
  animation: fadeIn 480ms ease forwards;
  animation-delay: 480ms;

  &__container {
    width: 500px;
    max-width: calc(100% - 128px);
    margin: 0 auto;
  }
  p {
    font-size: 1rem;
  }
  &__progressbar {
    width: calc(100% - 32px);
    margin: 16px auto;
  }
  &__form {
    padding: 1rem;
    position: relative;
    &__wave {
      animation: shake 500ms;
      animation-iteration-count: 3;
      animation-delay: 960ms;
      display: inline-block;
    }
    h1 {
      color: $blue700;
    }

    &__input {
      padding-bottom: 2rem;
      width: 100%;
    }

    &__dropdown {
      width: fit-content;
      width: 185px;
      margin-bottom: 32px;
    }

    &__button {
      display: flex;
      justify-content: space-between;

      &__single {
        justify-content: flex-end;
      }
    }
  }
}

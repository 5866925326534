@import "../../styles/theme.scss";

.TouchpointCard {
  background-color: $defaultBackground;
  margin-top: 1rem;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__title {
      margin-bottom: 0.5rem;
      padding-right: 1rem;
    }

    &__description {
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }

    &__footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 1rem;

      &__download {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        padding-right: 0.5rem;
        .Icon {
          margin-right: 0.5rem;
        }
        &__button {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__thumbnail {
    max-width: 250px;
    max-height: 148px;
    background-color: $white;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    overflow: hidden;

    &__wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &__img {
        cursor: pointer;
        height: 148px;
        margin: auto;
      }
      &__overlay {
        cursor: pointer;
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: 1.5rem;
        opacity: 0;

        &__text {
          display: flex;
          align-items: center;
          padding: 1.5rem;
          gap: 0.5rem;
        }
      }
      &:hover &__img {
        filter: blur(4px);
      }
      &:hover &__overlay {
        opacity: 1;
      }
    }

    @media (max-width: $sm) {
      margin-top: 1rem;
    }
  }

  &__preview {
    &__modal {
      .Modal__container {
        width: 90%;
        height: 90%;
        animation: slideUp 0.5s forwards;
      }

      .Modal__container__body {
        height: auto;
      }

      .Modal__container__action {
        position: absolute;
      }

      &__content {
        &__iframe {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          height: 85%;
          border: none;
        }
      }

      @keyframes slideUp {
        from {
          transform: translate(-50%, 100%);
        }
        to {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

@import "../../../styles/theme.scss";

.InputGroup {
  width: 100%;
  max-width: $questionsMaxWidth;

  &__group {
    &--row {
      display: flex;
    }

    &__option {
      margin-bottom: 0.5rem;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      &--row {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      &__clarification {
        align-self: center;
        text-align: right;
        cursor: pointer;

        &__button {
          border: none;
          padding: 2px;
          background-color: transparent;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__labels {
    color: $secondaryColor;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__clarification {
    gap: 1rem;
    padding-right: 1rem;
    align-items: center;

    &--rtl {
      direction: rtl;
    }
  }
}

@import "../../../styles/theme.scss";

$animationTimer: 480ms;

.LoadingIndicator {
  position: relative;
  display: block;
  width: 60px;
  height: 30px;
  margin-bottom: 32px;

  &__ball {
    position: absolute;
    height: 100%;
    width: 50%;
    border-radius: 100px;
    animation: $animationTimer ease-in-out alternate-reverse;
    animation-iteration-count: infinite;

    &:first-child {
      left: 0;
      background-color: $red500;
      animation-name: slideRight;
    }

    &:last-child {
      right: 0;
      background-color: $blue800;
      animation-name: slideLeft;
    }
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

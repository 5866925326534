@import "../../styles/theme.scss";

.ChapterLinkCard {
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  &__arc {
    height: 125px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__title {
    padding: 0 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__content {
    background-color: $defaultBackground;
    padding: 1rem;
    max-height: 100%;
    overflow-y: auto;

    &__title {
      margin-bottom: 1rem;
    }

    &__insights {
      display: flex;
      align-items: center;
      height: calc(100% - 2.5rem);
    }
  }
}

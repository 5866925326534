@import "../../styles/theme.scss";

.SurveyIntroductionEmail {
  margin-bottom: 1rem;
  &__languageTabs {
    &.Tabs {
      background-color: transparent;
      border-radius: 0;
      padding: 1rem 0 0 0;
    }

    .Tab {
      border: 1px solid $defaultBorderColor;
      border-radius: $borderRadius;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      &--selected {
        background-color: $defaultBorderColor;
      }
    }
  }

  &__languageTabpanel {
    padding: 1rem;
    margin-top: -1px;
    border: 1px solid $defaultBorderColor;
    border-radius: $borderRadius;
    border-top-left-radius: 0;
    margin-bottom: 1rem;
  }

  &__warning {
    margin-top: -1rem;
    z-index: 11;
    position: relative;
    float: right;
    @media (max-width: $md) {
      margin-top: -1.5rem;
    }

    &--primary {
      padding-left: 3px;
      color: $stoneBlue;
    }
    .Typography {
      font-style: italic;
    }

    &--input {
      position: relative;
      top: 1rem;
    }
  }

  &__info {
    background-color: $defaultBackground;
    margin-bottom: 1rem;
    display: flex;
    gap: 0.5rem;
  }

  &__saveButton {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  &__loading {
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  &__languageSelect {
    margin-bottom: 2rem;
  }

  &__preview {
    margin-bottom: 1rem;
    color: $roseOrange;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }

    &--disabled {
      opacity: 0.5;
      text-decoration: none;
      &:hover {
        cursor: default;
      }
    }
  }
}

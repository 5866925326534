@import "../styles/theme.scss";

.PipelinePage {
  &__setupCard {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Button {
      background-color: $green300;
    }
  }

  &__setupModal {
    .Modal__container {
      max-width: 95%;
      overflow: scroll;
    }
    .Modal__container__header {
      &__title {
        text-align: center;
      }
    }
  }
}

.ChartTooltip {
  display: flex;
  padding: 1rem;
  align-items: center;

  &__color {
    height: 12px;
    width: 12px;
    margin-right: 1rem;
    border-radius: 2px;
  }

  &__info {
    &__label {
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1rem;
    }

    &__count {
      line-height: 1rem;
      opacity: 0.65;
    }
  }

  &__ratio {
    margin-left: 2rem;

    .MuiTypography-root {
      font-weight: 500;
    }
  }
}

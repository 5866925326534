@import "../../styles/theme.scss";

.SurveySummary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 5rem;
  &__title {
    margin-bottom: 2rem;
  }
}

@import "../styles/theme.scss";

.SurveyLaunchPage {
  display: flex;

  &__survey {
    width: 100%;
    padding: 1.5rem;

    &__link {
      height: fit-content;
      display: block;
      margin-top: 2.4rem;
    }
    &__heading {
      margin-bottom: 2rem;
      text-align: center;
    }
    &__action {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
    &__loading {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
    }
  }
  &__info {
    padding: 1.5rem;
    width: 33%;
    border-left: 1px solid $defaultBorderColor;
    padding-left: 4rem;
  }
}

@import "../../../styles/theme.scss";

.Icon {
  &--clickable {
    cursor: pointer;
  }

  &--secondary {
    fill: $secondaryColor;
    stroke: $secondaryColor;
  }

  &--info {
    fill: $infoColor;
    stroke: $infoColor;
  }

  &--white {
    fill: #fff;
    stroke: #fff;
  }
}

@import "../../styles/theme.scss";

.QuestionBreakdown {
  &__info {
    margin-bottom: 1rem;
  }

  &__breakdown {
    display: flex;
    align-items: stretch;
    margin-bottom: 2rem;

    &__questions {
      width: 50%;

      &__divider {
        margin: 1.5rem 2rem 1.5rem 0;
      }

      &__tabs {
        border-radius: 0;
        padding: 0;

        &--grouped {
          .QuestionBreakdown__breakdown__questions__tabs__groupTitle {
            margin-top: 1rem;
          }
        }
      }

      &__tab {
        &__groupTitle {
          padding-right: 1rem;
          margin-bottom: 1rem;
          white-space: normal;
        }

        &:not(:first-of-type) {
          .QuestionBreakdown__breakdown__questions__tab__groupTitle {
            margin-top: 1rem;
          }
        }

        &--grouped {
          margin-left: 0.5rem;
          padding-left: 0.5rem;
          border-left: 1px solid $defaultBorderColor;
        }

        .Tab {
          border-radius: $borderRadius 0 0 $borderRadius;
          text-transform: none;
          max-width: unset;
          width: 100%;
          align-items: flex-start;
          text-align: left;
          opacity: 1;
        }
      }
    }

    &__answers {
      width: 50%;
      padding: 2rem;
      margin-bottom: -2rem;
      background-color: white;
      border-radius: $borderRadius 0 0 $borderRadius;
      margin-right: -2rem;

      &__title {
        text-transform: uppercase;
      }

      &__filters {
        padding-top: 2rem;

        &__button {
          margin: 0 0.5rem 0.5rem 0;
        }
      }
      &__noAnswer {
        margin-top: 2rem;
      }
    }

    &__noData {
      margin-left: 1rem;

      &__customMessage {
        margin: 1rem 0;
        font-style: italic;
      }
    }
  }
}

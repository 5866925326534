@import "../../styles/theme.scss";

.CustomQuestionsList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;

  &__header {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .MuiAccordion-root {
    border-radius: 8px;
    border: 1px solid $aldaStroke;
    box-shadow: none;
  }
  .MuiAccordion-root::before {
    display: none;
  }
  .MuiAccordionSummary-content {
    justify-content: space-between;
    align-items: center;
  }
  .Mui-disabled {
    background-color: transparent;
  }
}

@import "../../styles/theme.scss";

.PipelineJobLevelMapping {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    padding: 4rem 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    &__item {
      justify-content: space-between;
      align-items: center;
      width: 34rem;
      display: flex;
      border: 1px solid $aldaStroke;
    }
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .Button:only-child {
      margin-left: auto;
    }
  }
}

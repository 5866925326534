.TrainingPage {
  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    margin-bottom: 24px;
    @media (min-width: 1400px) {
      max-width: 1400px;
      padding-left: 24px;
      margin: 0 auto;
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
    &__select {
      margin-top: 0;
    }
  }
  &__container {
    margin-bottom: 48px;
    display: flex;
    align-content: center;
    @media (min-width: 1400px) {
      max-width: 1400px;
      margin: 0 auto;
    }
  }
  &__title {
    text-align: center;
    font-family: "studiofeixen";
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    margin: 4.5rem 0;
  }
}

@import "../../styles/theme.scss";

.TrainingModuleCard {
  position: relative;
  height: 100%;
  .Card {
    height: 100%;
    box-shadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);
    padding: 0;
  }
  &__innerCard {
    height: 100%;

    &--disabled {
      opacity: 0.3;
    }

    &__header {
      border-radius: 1rem 1rem 0 0;
      width: 100%;
      height: 137px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__img {
        max-height: 200px;
        border-radius: $borderRadius;
      }

      &__pill {
        position: absolute;
        min-width: 3rem;
        min-height: 1rem;
        background-color: $white;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        margin-top: -1.5rem;
        font-size: 0.75rem;
        svg {
          height: 0.875rem;
          width: 0.875rem;
          margin-right: 0.5rem;
        }
      }
    }
    &__content {
      position: relative;
      height: calc(100% - 144px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 1.5rem 2.25rem;
      border-radius: 1rem;
      background-color: $white;
      border-radius: 1rem;
      margin-top: -2.25rem;
      text-align: center;
      min-height: 140px;
      &__title {
        padding-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        &--capitalize {
          text-transform: capitalize;
        }
      }
      &__tag {
        display: none;
        justify-content: center;
        align-items: center;
        background-color: $grey100;
        padding: 6px;
        border-radius: 1rem;
        margin-bottom: 0.5rem;
        p {
          font-weight: 300;
          line-height: 1rem;

          font-size: 0.75rem;
        }
        svg {
          width: 0.875rem;
          height: 0.875rem;
          margin-right: 0.5rem;
        }
      }

      &__description {
        font-size: 0.875rem;
        line-height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap;
        margin-bottom: 0px;
        width: 100%;
        height: 60px;
      }
      &__button {
        border: none;
        background: transparent;
        transform: translate3d(0, 0, 0);
        transition: all 320ms ease;
        padding: 0 0.5rem 0 0.5rem;
        background-color: transparent;
        border-radius: 16px;

        &--disabled {
          svg path {
            fill: $grey500;
          }
        }
        svg {
          width: 2.25rem;
        }
        &:hover {
          transform: translate3d(0, -1px, 0);
          transition: all 320ms ease;
          cursor: pointer;
          background-color: $grey300;
        }
        &--disabled:hover {
          transform: translate3d(0, 0, 0);
          cursor: default;
          background-color: transparent;
        }
      }

      .Chip {
        margin-top: 0.5rem;

        .MuiChip-label {
          white-space: wrap;
          overflow: visible;
        }
      }
    }
  }
}

.TrainingModuleCard__modal {
  .Modal__container {
    max-width: 95%;
    height: fit-content;
  }

  .Modal__container__header {
    margin-left: 2.25rem;
    &__title {
      text-transform: uppercase;
      text-align: center;
    }
  }

  .Modal__container__body {
    padding: 0;
  }

  .Modal__container__action {
    position: absolute;
  }
}

.WorkplaceCultureDemographicFilter {
  width: 100%;

  .Select {
    margin-top: 0;

    &__input {
      width: 100%;
    }
  }
}

@import "../../../styles/theme.scss";

.Paper {
  border-radius: $borderRadius;
  padding: 1.5rem;
  box-shadow: none;

  @media (max-width: $sm) {
    padding: 0.5rem;
  }

  &--error {
    padding: 1rem;
  }

  &--secondary {
    background-color: $defaultBackground;
  }

  &--compact {
    padding: 1rem;
  }
}

@import "../styles/theme.scss";

.LoginPage {
  background-color: $grey100;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  &__header {
    position: absolute;
    top: 48px;
    left: 48px;

    @media (min-width: $md) {
      top: 64px;
      left: 96px;
    }
  }

  &__content {
    margin-top: 145px;
    height: 100%;

    @media (min-width: $md) {
      margin-top: 0;
      display: flex;
      flex-direction: row-reverse;

      &__background {
        width: 50%;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__heading {
      color: red;
      font-weight: 800;
      color: $blue800;
      padding-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .Select {
        margin-top: 0;
      }
    }

    &__image {
      width: calc(100% - 64px);
      margin-left: 32px;
      max-width: 760px;
    }

    &__loginContainer {
      z-index: 1;

      @media (min-width: $md) {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__login {
      background-color: $grey100;

      @media (min-width: $md) {
        box-shadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);
        width: 484px;
        max-width: calc(100% - 128px);
      }

      &__form {
        padding: 0px 2rem 2rem 2rem;
        gap: 2.5rem;
        @media (min-width: $md) {
          padding: 2rem;
        }
        &__input {
          display: block;
          border-radius: $borderRadius;
          padding-bottom: 1.5rem;
          border-radius: 1px;

          .MuiInputBase-formControl {
            width: 100%;
            height: 3rem;
            font-size: 1rem;
          }
        }

        &__error {
          display: flex;
          background-color: $red200;
          align-items: center;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
          gap: 0.5rem;
        }

        button {
          width: 100%;
          a {
            color: inherit;
          }
        }

        &__google {
          &__divider {
            position: relative;
            margin-bottom: 2rem;
            margin-top: 2rem;
            width: 100%;
            font-size: 1rem;
            text-align: center;
            color: $blue800;
            &:before {
              content: "";
              width: calc(50% - 48px);
              display: block;
              position: absolute;
              left: 0rem;
              height: 1px;
              background-color: $grey500;
              top: 0.5rem;
            }
            &:after {
              content: "";
              width: calc(50% - 48px);
              display: block;
              position: absolute;
              right: 0rem;
              height: 1px;
              background-color: $grey500;
              top: 0.5rem;
            }
          }
        }
      }
    }
  }
}

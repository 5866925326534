@import "../../styles/theme.scss";

.EmptyState {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    background-color: $green300;

    &:hover {
      background-color: $green400;
    }
  }

  &--widget {
    flex-direction: column;
    align-items: normal;
    .EmptyState__text {
      align-items: center;
      text-align: center;
    }
    .EmptyState__actions {
      margin: 0rem 0 1rem 0;
      padding: 2.5rem 2rem;
    }
  }
}

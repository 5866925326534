@import "../../styles/theme.scss";

.Question {
  min-width: $questionsMaxWidth;
  text-align: center;
  padding-bottom: 0.5rem;

  @media (max-width: $sm) {
    min-width: 100%;
    max-width: $questionsMaxWidth;
  }

  &__preamble {
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    color: $blue800;

    @media (min-width: 600px) {
      padding: 0 1.5rem;
    }
  }

  &__question {
    margin-bottom: 2.5rem;
    @media (max-width: $sm) {
      margin-bottom: 1rem;
      padding: 0 1rem;
    }

    &__clarification {
      white-space: pre-line;
      margin-top: 1rem;
      color: $blue800;
      font-style: italic;
      @media (max-width: $sm) {
        margin-top: 0.5rem;
      }
    }

    &__help {
      margin-top: 1.5rem;
      color: $grey700;
      @media (max-width: $sm) {
        margin-top: 0.5rem;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__answer {
      color: $blue400;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;

      &__text {
        margin-top: 1.5rem;
        margin-bottom: 3rem;
        height: 2.5rem;
      }
    }
  }

  &__info {
    gap: 1rem;
    padding-right: 1rem;
    align-items: center;
  }
}

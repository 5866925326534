@import "../../styles/theme.scss";

.SurveyStart {
  flex-direction: row;
  max-width: $xl;

  @media (max-width: $sm) {
    max-width: inherit;
    padding: 1rem;
  }

  &--preview {
    .StartSurvey__intro__content__language {
      display: block;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__background {
    &__illustration {
      height: 430px;
      @media (max-width: $sm) {
        display: none;
      }
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
      @media (max-width: $sm) {
        height: 100%;
        justify-content: center;
      }

      &__title {
        margin-bottom: 0.5rem;
      }

      &__language {
        max-width: 200px;
      }

      &__time {
        gap: 1.5rem;
        color: $defaultIconColor;
        display: flex;
        margin-top: 2rem;
        @media (max-width: $sm) {
          margin-top: 1rem;
        }

        &__item {
          display: flex;
          gap: 0.5rem;

          &__text {
            position: relative;
            bottom: 2px;
            opacity: 0.65;
          }

          &:first-child {
            margin-right: 1.5rem;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        max-width: 22rem;
        padding-left: 0;
        gap: 1.5rem;
        padding-bottom: 1rem;
        @media (max-width: $sm) {
          gap: 1rem;
          padding: 1rem 0;
        }

        &__startInfo {
          background-color: $white;
          box-shadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);
          border-radius: 16px;
          display: flex;
          margin: 1.5rem 0;
          align-items: center;
          padding: 1rem 1.5rem;
          @media (max-width: $sm) {
            margin: 0.5rem 0;
          }

          .Typography--regular {
            opacity: 0.65;
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &__icon {
            padding-right: 1.5rem;
          }
        }

        &__terms {
          margin-left: 0;
          margin-bottom: 1rem;
          max-width: 22rem;
          transition: box-shadow 0.3s ease;
        }
      }

      .Button {
        width: fit-content;
      }
    }
  }

  .MuiCheckbox-colorError {
    .MuiSvgIcon-root {
      animation: highlight 2s ease-out;
    }
  }
}

@keyframes highlight {
  0% {
    box-shadow: 0 0 10px 5px rgba(227, 124, 92, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(227, 124, 92, 0);
  }
}

.StartSurveyStory {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 0;
}

@import "../../styles/theme.scss";

.ActionPlanTimeline {
  height: 100%;
  align-content: center;
  padding: 3rem 0;
  &__noData {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 280px;
    margin: auto;

    &__icon {
      height: 88px;
      width: 88px;
      border-radius: 50%;
      background-color: $defaultBackground;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.5rem;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__info {
        text-align: center;
        color: $grey700;
      }
    }
  }

  &__modal {
    .Modal__container {
      max-width: fit-content;
    }
    .Modal__container__body {
      padding: 1.5rem;
    }
    .Modal__container__action {
      position: absolute;
    }
  }
}
.ActionPlanRail {
  position: relative;

  &__rail {
    width: 100%;
    height: 24px;
    border-radius: $borderRadius;
    margin-bottom: 0.5rem;
    background: $yellow200;
  }
}

.TimelineEventInfo {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 480px;

  &__title {
    padding-right: 2.5rem;
  }
  &__dateContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}

@import "../../styles/theme.scss";

.PayEquityRail {
  position: relative;

  &__rail {
    width: 100%;
    height: 24px;
    background: linear-gradient(
      90deg,
      $purple800 0%,
      $blue300 49.67%,
      $roseOrange 93.75%
    );
    border-radius: $borderRadius;
    margin-bottom: 0.5rem;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
  }
}

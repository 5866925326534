@import "../../styles/theme.scss";
.TeamMembers {
  margin-bottom: 3rem;

  &__divider {
    margin: 1rem 0 2rem 0;
  }

  &__inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &__action {
      align-self: flex-end;
    }
  }

  &__title {
    margin: 1.5rem 0;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &--loading {
      gap: 1rem;
      margin-top: 2rem;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__details {
        display: flex;
        align-items: center;
        gap: 1rem;

        &__avatar {
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
      }

      &__info {
        background-color: $grey400;
        padding: 0 1rem;
        border-radius: 8px;
      }
    }
  }
}

@import "../../styles/theme.scss";

.Integration {
  &__card {
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &--actions {
      justify-content: space-between;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      flex-grow: 2;
      padding-right: 2.5rem;
      align-items: center;

      &__img {
        display: flex;
      }

      &--secondary {
        color: $secondaryColor;
      }
    }

    &__actions {
      display: none;

      &__button {
        margin-right: 0.5rem;
      }
    }

    &__loading {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__terms {
    &__actions {
      display: flex;
      justify-content: center;
    }
  }
}

@import "../../styles/theme.scss";

.SurveyInfo {
  display: flex;
  align-items: self-end;
  @media (max-width: $sm) {
    justify-content: center;
  }

  &__text {
    margin-bottom: 1.3rem; // To get in line with logo as align-items is occupied
    margin-right: 0.5rem;
    @media (max-width: $sm) {
      margin-bottom: 1rem;
    }
  }

  &__logo {
    width: 105px;
    height: 66px;
    @media (max-width: $sm) {
      width: 80px;
      height: 50px;
    }
  }
}

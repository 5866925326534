@import "../../styles/theme.scss";

.WorkplaceCultureWidget {
  height: 392px;
  padding: 0 1rem;

  &__item {
    height: 100%;
  }

  &__card {
    height: calc(100% - 4rem);
    box-shadow: 0px 8px 32px 6.24px rgba(139, 139, 139, 0.25);

    @media (max-width: $sm) {
      height: calc(100% - 2rem);
    }

    .SurveyScoreCard__title {
      text-align: left;
    }
  }
}

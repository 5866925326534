@import "../../styles/theme.scss";
@import "../../styles/mixins.scss";

.Navigation {
  box-shadow: none;
  background-color: $defaultBackground;

  &__container {
    z-index: 999;
    background-color: $defaultBackground;
    border-bottom: 1px $defaultBorderColor solid;
  }

  &__language {
    margin-top: 0;
    margin-right: 1rem;
  }

  &__icon {
    margin-left: 4px;
    margin-top: 2px;
  }
}

.MenuBox {
  flex-grow: 1;

  @media (min-width: $md) {
    justify-content: center;
    align-items: center;
  }
}

.MenuItem {
  color: $secondaryColor;
  min-width: 7rem;
  margin: 0 0.5rem;
  transition: none;
  font-size: 1rem;
  @media (min-width: $md) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  span {
    text-transform: none;
    font-size: 1rem;
  }

  &:hover {
    color: $defaultText;
    svg path {
      stroke: $defaultText;
    }
  }

  &--active {
    color: $defaultText;

    svg path {
      stroke: $defaultText;
    }

    font-weight: 700;

    @include illustrationBorder;

    &:hover {
      background-color: transparent;
    }
  }
}

.UserSettingsBox {
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  margin-left: 0.5rem;

  &__button {
    padding: 0.5rem;
    background-color: $defaultBackground;
  }

  &__menu {
    margin-top: 0.5rem;
  }
}

@import "../../styles/theme.scss";

.InsightsCard {
  &__title {
    margin-bottom: 1rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: $defaultBackground;
    padding: 1rem;
    border-radius: $borderRadius;

    &__item {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      &__icon {
        align-self: center;
      }

      &__accent {
        color: $roseOrange;
        font-weight: bold;
      }

      &__benchmark {
        font-style: italic;
      }
    }
  }
}

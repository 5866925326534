@import "../../../styles/theme.scss";

.PeopleChart {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 4rem;

  @media (max-width: $lg) {
    padding-top: 2rem;
  }

  @media (min-width: $md) {
    min-height: 350px;
  }

  &__container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    &__people {
      display: flex;
      width: 44rem;
      max-width: 44rem;

      &--active {
        filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
      }

      @media (max-width: 1200px) {
        align-items: center;
        width: 17.5rem;
      }

      .MuiGrid-item {
        padding: inherit;
      }
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__column {
      padding-bottom: 1rem;

      &__item {
        min-width: 15rem;
        justify-content: space-around;
      }
    }

    &__row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      gap: 0.5rem;

      &__item {
        display: flex;
        align-items: center;
      }
    }
  }

  &--small {
    align-items: center;
    flex-direction: column;
    padding: 0rem;
    justify-content: space-between;

    .PeopleChart__container {
      padding: 0.5rem;
    }
    .PeopleChart__container__people {
      max-width: 13rem;
      max-height: 16rem;
      overflow-y: scroll;
    }
    .PeopleChart__container__people__item {
      height: 22px;
    }
    .PeopleChart__legend__row {
      overflow-y: scroll;
      max-height: 3.5rem;
      padding-top: 0;
      row-gap: 0.5rem;
    }
  }
}

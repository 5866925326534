@import "../../styles/mixins.scss";
@import "../../styles/theme.scss";

.DiversityRatioBreakdown {
  text-align: left;
  border: 2px solid $grey400;

  &__filters {
    margin-top: 1rem;
    max-width: fit-content;
    margin: auto;
  }
}

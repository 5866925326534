@import "../../styles/theme.scss";

.CustomQuestionsOverview {
  &__title {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  &__subtitle {
    margin-bottom: 2rem;
  }

  &__questionList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;

    &__header {
      display: flex;
    }

    .MuiAccordion-root {
      border-radius: 8px;
      border: 1px solid $aldaStroke;
      box-shadow: none;
    }
    .MuiAccordion-root::before {
      display: none;
    }
    .MuiAccordionSummary-content {
      justify-content: space-between;
      align-items: center;
    }
  }

  &__action {
    margin-bottom: 2rem;
  }
}

.SurveyDispatchEngagement {
  min-width: 100px;

  &__progress--public {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
  }
}

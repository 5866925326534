@import "../../styles/theme.scss";

.PipelineLevelSetup {
  min-width: 24rem;

  &__title {
    padding-bottom: 2rem;
  }
  &__levels {
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }

    &__item {
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      display: flex;

      .Input {
        padding-top: 0;
      }

      &:hover {
        .PipelineLevelSetup__levels__item__actions__close {
          display: block;
        }
      }
      .Button--icon {
        box-shadow: none;
        min-width: 30px;
        width: 30px;
        height: 30px;
      }

      &__input {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      &__actions {
        display: flex;
        align-items: center;
        position: relative;
        &__order {
          margin-right: 1rem;
        }

        &__close {
          position: absolute;
          left: 0px;
          bottom: 0px;
          display: none;
        }
      }
    }
  }

  &__inputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
  }

  &__buttonWrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__loading {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__item {
        justify-content: space-between;
        align-items: center;
        width: 34rem;
        display: flex;
        border: 1px solid $aldaStroke;
      }
    }
  }
}

@import "../../../styles/theme.scss";

.Chip {
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: bold;

  &.MuiChip-filledSecondary,
  &.MuiChip-filledDefault {
    background-color: $defaultBackground;
  }

  &.MuiChip-filledPrimary,
  &.MuiChip-filledInfo,
  &.MuiChip-filledSuccess {
    background-color: $blue100;
  }

  &.MuiChip-filledError {
    background-color: $red100;
  }

  &.MuiChip-filledWarning {
    background-color: $grey900;
  }
}

@import "../../styles/theme.scss";

.Company {
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  &__icon {
    min-width: 32px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    min-width: 150px;

    &__name {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
    }

    &--secondary {
      color: $secondaryColor;
      display: flex;
    }
  }
}

@import "../../../styles/theme.scss";

.Typography {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 300;
  color: $grey900;
  font-size: 1.75rem;
  line-height: 3.5rem;

  &--secondary {
    color: $grey700;
  }

  &--error {
    color: $roseOrange;
  }

  &--coal {
    color: $grey900;
  }

  &--light {
    color: white;
  }

  &__mobile {
    &--display1 {
      font-size: 4rem;
      line-height: 5.5rem;
      letter-spacing: -2px;
      font-weight: 800;
    }

    &--display2 {
      font-size: 2.5rem;
      line-height: 3rem;
      letter-spacing: -1px;
      font-weight: 800;
    }

    &--h1 {
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.5px;
      font-weight: 800;
    }

    &--h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -0.25px;
      font-weight: 800;
    }

    &--h3 {
      font-size: 1.125rem; // 18 px
      line-height: 1.625rem; // 26 px
      letter-spacing: -0.25px;
      font-weight: 800;
    }

    &--handwrittenH1 {
      font-family: "Lore";
      font-size: 2rem;
      font-weight: 800;
    }

    &--handwrittenH2 {
      font-family: "Lore";
      font-size: 1.5rem;
      font-weight: 800;
    }

    &--body1 {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &--body2 {
      font-size: 0.875rem; // 14px
      line-height: 1.25rem; // 20px
    }

    &--button1 {
      font-size: 0.875rem;
      line-height: 1rem;
    }

    &--button2 {
      font-size: 0.75rem; // 12px
      line-height: 1rem;
    }

    &--label1 {
      font-family: "studiofeixen mono";
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 1px;
    }

    &--label2 {
      font-family: "studiofeixen mono";
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 1px;
    }

    &--label3 {
      font-family: "studiofeixen mono";
      font-size: 0.625rem; // 10px
      line-height: 0.875rem;
      letter-spacing: 0.25px;
    }

    &--caption {
      font-size: 0.75rem;
      line-height: 1.25rem;
      letter-spacing: 0.25px;
    }
  }

  @media (min-width: $sm) {
    &__desktop {
      &--display1 {
        font-size: 5rem;
        line-height: 5.5rem;
        letter-spacing: -2px;
        font-weight: 800;
      }

      &--display2 {
        font-size: 3rem;
        line-height: 3.5rem;
        letter-spacing: -1px;
        font-weight: 800;
      }

      &--h1 {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: -0.5px;
        font-weight: 800;
      }

      &--h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: -0.25px;
        font-weight: 800;
      }

      &--h3 {
        font-size: 1.125rem;
        line-height: 1.625rem;
        letter-spacing: -0.25px;
        font-weight: 800;
      }

      &--body1 {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      &--body2 {
        font-size: 0.875rem; // 14px
        line-height: 1.25rem; // 20px
      }

      &--button1 {
        font-size: 0.875rem;
        line-height: 1rem;
      }

      &--button2 {
        font-size: 0.75rem; // 12px
        line-height: 1rem;
      }

      &--label1 {
        font-family: "studiofeixen mono";
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 1px;
      }

      &--label2 {
        font-family: "studiofeixen mono";
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 1px;
      }

      &--label3 {
        font-family: "studiofeixen mono";
        font-size: 0.625rem; // 10px
        line-height: 0.875rem;
        letter-spacing: 0.25px;
      }

      &--caption {
        font-size: 0.75rem;
        line-height: 1.25rem;
        letter-spacing: 0.25px;
      }
    }
  }

  @media (max-width: $md) {
    &__mobile {
      &--body2 {
        font-size: 0.875rem; // 14px
        line-height: 1.25rem; // 20px
      }
    }
  }

  &--regular {
    font-weight: 400;
  }

  &--bold {
    font-weight: 800;
  }

  &--italic {
    font-style: italic;
  }
}

@import "./styles/theme.scss";

body {
  margin: 0;
  font-family: "studiofeixen", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $defaultText;
  font-size: 14px;
  background-color: $defaultBackground;
  letter-spacing: 0em;
  word-spacing: 0em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  -webkit-tap-highlight-color: transparent;
}

@import "../../styles/theme.scss";

.SurveySettings {
  min-width: 460px;

  &__content {
    position: relative;

    &__info {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-radius: 8px;
      }
      &__title {
        padding: 0 0 0.5rem 0.5rem;

        @media (max-width: $sm) {
          padding-top: 0.5rem;
        }
      }

      &__value {
        display: flex;
        flex: 0 1 100%;
      }

      &__dateRange {
        display: flex;

        &__divider {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 1.2rem;
        }

        @media (max-width: $sm) {
          &__divider {
            display: none;
          }
        }
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      gap: 1rem;
    }
  }
}

.SurveySettingsReminder {
  display: flex;
  flex-direction: column;

  &__inputContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    &__datePicker {
      flex: 0 1 100%;
    }

    .Button--icon.Button--secondary {
      box-shadow: none;
    }
  }

  &__addReminder {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    border-radius: 8px;
    background-color: $white;
  }

  &__info {
    display: flex;
    justify-content: center;
  }
}

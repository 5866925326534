@import "../../../styles/theme.scss";

.Input {
  margin-top: 1rem;

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      top: 0;
    }
  }

  .MuiInputLabel-asterisk {
    display: none;
  }

  & legend {
    display: none;
  }
}

/* NOTE: these styles are hardcoded in the styles for 
 * Storybook in preview-head.html, 
 * so changes should be applied there as well 
*/
.MuiFormControl-root {
  .MuiFormLabel-root {
    color: $stoneBlue;

    &.Mui-focused {
      margin-top: -0.5rem;
    }

    &.MuiInputLabel-shrink {
      margin-top: -0.5rem;
      margin-left: -0.9rem;
    }
  }
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-radius: $borderRadius;
      border-color: $defaultBorderColor;

      &:hover {
        border-color: red;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $grey700;
      }
    }
  }
}

.InputOption {
  margin: 0 5px 0.5rem 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transition: background-color 240ms ease;
  border: 2px solid transparent;
  @media (max-width: $sm) {
    min-height: 0px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    cursor: pointer;
  }

  &:hover {
    background-color: $grey300;
    transition: background-color 240ms ease;
  }

  &--selected {
    border: 2px solid $yellow200;
    transition: all 240ms ease;
    background-color: $yellow100;
    position: relative;
    transition: background-color 240ms ease;

    &:hover {
      background: $yellow100;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  &__label {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 1.125rem;
    color: var(--grey900);
    box-shadow: 0px 8px 16px 6.24px rgba(139, 139, 139, 0.25);
    padding: 1.3rem 1rem;
    @media (max-width: $sm) {
      padding: 0.8rem 1rem;
    }
    input[type="checkbox"] + & {
      justify-content: space-between;
    }

    &__text {
      display: flex;
      width: 95%;
      text-align: left;
    }

    &__checkbox {
      background-color: $white;
      height: 14px;
      width: 14px;
      border-radius: 16%;
      display: block;
      margin-top: 4px;
      position: relative;
      border: 1px solid $grey500;
      &--selected {
        background-color: $white;
        border: 1px solid $yellow200;
        &::before {
          content: "";
          position: absolute;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 10px;
          background-color: $yellow200;
          border-radius: 16%;
        }
      }
    }
  }
}

@import "../styles/theme.scss";

.SettingsPage {
  &__title {
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__version {
    position: sticky;
    bottom: 1rem;
    right: 1.5rem;
    text-align: right;
    width: 100%;
  }
}

@import "../../../styles/theme.scss";

.TrainingModuleContent {
  flex: 1;
  overflow-y: auto;
  padding: 1.25rem;

  &__title {
    word-wrap: break-word;
    padding-bottom: 1rem;

    &--capitalize {
      text-transform: capitalize;
    }
  }

  &__tags {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    .Chip {
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  &__info {
    display: flex;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }

  &__languages {
    display: flex;
    margin-bottom: 0.5rem;

    &__icon {
      margin-right: 0.5rem;
      color: $secondaryColor;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__text {
      margin-right: 0.5rem;
    }
  }
}

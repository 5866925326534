.SurveyDispatchOverview {
  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__title {
      margin: 1rem 0;
    }
  }
}

@import "../../styles/theme.scss";

.CustomQuestionForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__options {
    margin-bottom: 2rem;
    input:disabled ~ fieldset {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__action {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      border-radius: 8px;
      background-color: $white;
    }
  }
  &__item {
    border: 2px solid $grey400;
    border-radius: 16px;
    padding: 2rem;
    margin-bottom: 2rem;

    &--accordion {
      border: none;
      padding: 0 3rem;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &__action {
        margin-left: auto;
      }
    }

    &__question {
      .MuiInputBase-root {
        padding-bottom: 0.5rem;
        padding-top: 0px;
        font-size: 1.5rem;
        font-weight: bold;
      }

      fieldset {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0px !important;
      }
    }
    &__withButton {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      .Input {
        width: 100%;
      }
      button {
        margin-top: 0.73rem;
        margin-left: 0.3rem;
      }
    }
    .Select {
      justify-content: flex-start;
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
    }

    &__inputWrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 1rem;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
}

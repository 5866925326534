@import "../../styles/theme.scss";

.NoDataState {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;

  &__icon {
    height: 88px;
    width: 88px;
    border-radius: 50%;
    background-color: $defaultBackground;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__info {
      text-align: center;
      color: $grey700;
      max-width: 80%;
    }
  }

  &--light {
    flex-direction: row;
    max-width: none;
    margin: 0;

    .NoDataState__icon {
      height: 3rem;
      width: 3rem;
      margin-bottom: 0;
      margin-right: 0.5rem;
      min-width: 3rem;
    }

    .NoDataState__text {
      gap: 0;
      flex-grow: 1;
      align-items: flex-start;
    }
  }
}

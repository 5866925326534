@import "../styles/theme.scss";

.ErrorPage {
  margin: 24px;

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    margin-left: -180px;
    margin-top: -80px;
    text-align: center;

    &__heading {
      color: $grey700;
      letter-spacing: -1px;
      line-height: 60px;
      font-size: 60px;
      font-weight: 100;
      margin: 0;
      text-shadow: 0 1px 0 #fff;
    }

    &__paragraph {
      color: $grey700;
    }
  }
}

@import "../styles/theme.scss";

.SignUpPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;

  &__header {
    position: absolute;
    top: 48px;
    left: 48px;

    @media (min-width: $md) {
      top: 64px;
      left: 96px;
    }
  }
}

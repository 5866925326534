@import "../../styles/theme.scss";

.Header {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  height: calc($headerHeight - 1px);
  align-items: center;

  &--rtl {
    direction: rtl;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: $sm) {
      width: 90%;
    }
    &__logo {
      margin-top: 1rem;
    }

    &__language {
      margin: 0.5rem 2rem 0 0;
    }

    &__action {
      .Button--icon:hover {
        background-color: unset;
      }
    }

    &__info {
      flex-grow: 2;
      text-align: right;
      padding-right: 0.5rem;
      text-transform: uppercase;
    }

    &__action {
      padding-bottom: 0.5rem;
    }
    @media (max-width: $sm) {
      &__logo,
      &__info,
      &__language {
        height: 35px;
        width: auto;
        margin-top: 0;
      }
    }
  }

  @media (max-width: $sm) {
    border-bottom: none;
    justify-content: space-around;
    padding: 0.5rem 0 0 0;

    &--start {
      display: none;
    }

    &--stack {
      flex-direction: column-reverse;

      .Header__progress .ProgressBar .ProgressBar__info {
        margin-right: 1rem;
      }
    }
  }

  &__progress {
    .ProgressBar {
      padding: 0;
    }
    @media (min-width: $sm) {
      display: none;
    }
  }
}

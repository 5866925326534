.TrainingModuleOverviewFooter {
  position: sticky;
  bottom: 0;
  z-index: 98;
  background-color: white;
  border-radius: 0rem 0rem 1rem 1rem;

  display: flex;
  justify-content: space-between;
  padding: 1rem;
  text-align: center;
  flex-wrap: wrap;
  width: calc(100% - 2rem);

  &__link {
    text-decoration: none;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__assets {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    &__dropdown {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 0.5rem;
    }
    &__select {
      min-width: 200px;
      margin-top: 0;

      &__item {
        justify-content: space-between;
      }
    }
  }
  &__module {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    &__disabled {
      text-align: left;
      max-width: 40%;
    }
    &__download {
      display: flex;
      align-items: center;
      &__button {
        margin-right: 0.5rem;
        padding: 1rem;
        .Icon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

@import "../styles/theme.scss";

$pageTopPadding: 1rem;

.SurveyPage {
  background-color: $white;
  position: relative;
  width: 100%;

  &--loading {
    height: 100vh;
  }

  &--backupFont {
    font-family: "Arial", sans-serif;
  }

  &__page {
    height: calc(100dvh - $headerHeight - $pageTopPadding);
    overflow-y: auto;
    padding: $pageTopPadding 2rem 0 2rem;
    margin: auto;

    @media (max-width: $sm) {
      padding: 0.5rem 0.5rem 0 0.5rem;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey500;
      border-radius: 4px;
    }
  }

  &__progress {
    position: absolute;
    right: 0;
    top: calc($headerHeight + $pageTopPadding);
    @media (max-width: $sm) {
      display: none;
    }
  }

  &__info {
    align-items: center;

    &--rtl {
      direction: rtl;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-left: 1rem;
    }
  }

  &__progressInfo {
    .Modal__container,
    .Modal__background {
      left: 82%;
    }

    &--rtl {
      direction: rtl;
    }

    &__text {
      flex-direction: column;
      gap: 0.5rem;
    }

    @media (max-width: $lg) {
      z-index: 2;
      .MuiBackdrop-root {
        backdrop-filter: blur(4px);
        background: rgba(239, 239, 239, 0.4);
      }
      .Modal__container,
      .Modal__background {
        left: 50%;
      }
    }
  }
}

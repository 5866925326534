.WorkplaceCultureScaleSettings {
  padding: 0 1rem;
  &__slider {
    padding: 3rem 0 1rem 0;
    margin-right: 0rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

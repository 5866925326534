@import "../../styles/theme.scss";

.SurveyDispatchStatusCard {
  height: 100%;
  &__header {
    margin-bottom: 0.5rem;

    &--title {
      text-align: center;
    }
  }

  &__content {
    height: calc(100% - 28px);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-between;
  }

  &__notLaunched {
    margin-top: 1rem;
    white-space: pre-wrap;
    &__status {
      text-align: center;
      margin-top: 1.5rem;
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;

    &--notLaunched {
      .Button {
        width: 100%;
      }
    }
  }

  &__period {
    &__progress {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;

      &__bar {
        display: flex;
        align-items: center;

        @media (max-width: $md) {
          justify-content: center;
        }
      }

      &__dates {
        font-weight: bold;
        text-wrap: wrap;
      }

      &__reminder {
        padding-top: 0.5rem;
        font-style: italic;
      }
    }
  }
}

@import "../../../styles/theme.scss";

$animationTime: 240ms;

.DashboardWidget {
  &__container {
    height: $dashboardWidgetHeight;
    display: flex;

    &--small {
      height: 300px;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .MuiCardActionArea-root {
    height: initial;
  }

  &__header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    transform: translate3d(0, 0, 0);
    transition: all $animationTime ease;
    margin-bottom: 0.5rem;
    z-index: 1;
    text-align: center;

    &__arrowIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: transparent;
      transition: background-color $animationTime ease;
      svg {
        fill: $blue600;
      }
    }

    &__icon {
      height: 60px;
      width: 60px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-shadow: 0px 12px 30px 12px rgb(194 194 194 / 25%);
        transition: all 320ms ease;
        transform: translate3d(0, -1px, 0);

        > .DashboardWidget__header__arrowIcon {
          background-color: $blue300;
          transition: background-color $animationTime ease;
        }
      }
    }
  }

  &--preview {
    position: relative;
    .DashboardWidget__header {
      pointer-events: none;
    }

    .DashboardWidget__content {
      position: relative;

      &__previewOverlay {
        width: 100%;
        height: calc(
          $dashboardWidgetHeight - 1rem - $dashboardWidgetHeaderHeight
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1001;
        backdrop-filter: blur(4px);
        background: rgba(255, 255, 255, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.NavArrow {
  &--up {
    transform: rotate(180deg);
  }

  &--right {
    transform: rotate(270deg);
  }

  &--left {
    transform: rotate(90deg);
  }
}

@import "../../styles/theme.scss";

.SurveyRecipients {
  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    align-self: flex-end;
  }

  &__card {
    display: flex;
    flex-direction: column;

    &__status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;

      &__title {
        display: flex;
        align-items: center;
        &__icon {
          margin-right: 1rem;
        }
      }
    }

    &__tabs {
      background-color: transparent;
      flex-direction: column;
      align-items: center;

      margin: 0 auto;
      border-radius: 0;
      .Tab {
        padding-bottom: 0;
        border-bottom: 2px solid $grey400;
        border-radius: 0;
      }

      .Tab--selected {
        background-color: $grey400;
        font-weight: bold;
        border-bottom: 2px solid $stoneBlue;
        border-radius: 0;
        color: $stoneBlue;
        background-color: transparent;
        padding-bottom: 0;
      }
    }

    &__panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 2rem 1rem 2rem;
      &__fetchButton {
        svg {
          margin-right: 0.5rem;
        }
      }
      &__text {
        text-align: center;
        padding-bottom: 1.5rem;
      }

      &__error {
        padding-top: 1rem;
      }
    }

    &__action {
      padding-top: 0.7rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
    }
  }
  &__modal {
    .Modal__container {
      min-width: 20rem;
    }
    .Modal__container__body {
      max-height: 40rem;
    }

    &__list {
      list-style-type: none;
      padding: 0.5rem;
      margin-top: 0;

      &__item {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        border-bottom: 1px solid $grey400;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@import "../../styles/theme.scss";

.TeamMemberInviteForm {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey400;
  border-radius: $borderRadius;
  padding: 1rem;

  &__inputContainer {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;

    &__action {
      top: 1.5rem;
    }
  }

  &__buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 2rem;
  }
}

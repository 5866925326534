@import "../../styles/theme.scss";

.SurveyReadyStatus {
  text-align: center;
  &__checklist {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__item {
      display: flex;
      gap: 0.5rem;
      color: $white;
    }
  }
}

@import "../../styles/theme.scss";

.SurveyDispatchCard {
  width: 100%;

  &__card {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiCardActionArea-focusHighlight {
      background: transparent;
    }

    @media (max-width: $md) {
      flex-wrap: wrap;
      height: auto;
    }

    &__info {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;

      &__dates {
        padding-right: 0.5rem;
        text-align: right;
      }

      &__engagement {
        min-width: 100px;
        text-align: center;
      }

      &__readyStatus {
        text-align: center;
      }

      .ScoreArc {
        margin-left: 1rem;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      padding-left: 0.5rem;
    }
  }

  &__modal {
    .Modal__container {
      max-width: 95%;
      height: fit-content;
      overflow-y: scroll;
    }

    .Modal__container__header {
      margin-left: 2.25rem;
      &__title {
        text-transform: uppercase;
        text-align: center;
      }
    }

    .Modal__container__body {
      justify-content: center;
    }
  }
}

@import "../../styles/theme.scss";

.SurveyEmailPreview {
  &__subject {
    &__title {
      font-weight: bold;
    }
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $defaultBorderColor;
  }

  &__email {
    &__customMessage {
      background: $defaultBackground;
      padding: 20px;
      border-radius: calc($borderRadius / 2);
      white-space: pre-line;
    }

    padding: 0.5rem;
  }
}

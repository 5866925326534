@import "../../styles/theme.scss";

.PayEquityOverview {
  padding: 2rem;
  text-align: center;
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  &__header {
    height: 53px;
    margin-bottom: 2.5rem;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__viewSelector {
      width: 100%;

      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  &__filters {
    align-self: center;
    margin: 0 auto;
  }

  &__scale {
    margin-top: 12rem;
    margin-bottom: 10rem;
  }

  &__loading {
    height: 3rem;
  }

  &--container.PayEquityOverview--widget {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    p {
      font-weight: 300;
    }
  }

  &--widget {
    padding: 0;
    height: 100%;
    flex-grow: 1;
    width: inherit;

    .PayEquityOverview--noData {
      justify-content: flex-end;
    }

    .PayEquityRail__rail {
      margin-bottom: 0;
    }

    .PayEquityOverview__scale {
      margin-bottom: 0;
    }
  }

  &--widget.PayEquityOverview--loading {
    height: 100%;
  }

  &__loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@import "../../styles/theme.scss";

.SurveyFlow {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $questionsMaxWidth;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    justify-content: center;
    padding-bottom: $footerHeight;
    @media (max-width: $sm) {
      padding-bottom: $mobileFooterHeight;
    }

    &--rtl {
      direction: rtl;
    }

    &--preview {
      .Question__options {
        pointer-events: none;
      }

      .InputGroup__group__option__clarification {
        pointer-events: auto;
      }
    }
  }

  &--start {
    @media (max-width: $sm) {
      height: 100vh !important;

      .SurveyFlow__content {
        padding-bottom: 0;
      }
    }

    @media (min-width: $md) {
      max-width: initial;

      .SurveyFlow__content {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        padding-bottom: 0;
      }

      .SurveyFlow__footer__actions {
        flex-direction: row-reverse;
        padding-left: 0;
      }

      .Button {
        background-color: $yellow200;
        color: black;
      }
    }
  }

  @media (max-width: $sm) {
    .SurveyFlow__footer {
      position: absolute;
      left: 0;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 38rem;
    height: $footerHeight;
    left: calc(50% - 38rem / 2 + 0.5px);
    z-index: 2;
    background-color: $white;
    @media (max-width: $sm) {
      height: $mobileFooterHeight;
    }

    &__actions {
      padding: 0.5rem 1.5rem 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: $sm) {
        padding-top: 0.5rem;
      }

      &__previous {
        box-shadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);
        @media (max-width: $sm) {
          height: 40px;
          font-size: 0.9rem;
          padding: 1rem 1.5rem;
        }
      }

      &__next {
        @media (max-width: $sm) {
          height: 40px;
          font-size: 0.9rem;
          padding: 1rem 1.5rem;
          min-width: 10rem;
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      margin-top: 2px;
      stroke: white;

      &--skip,
      &--disabled {
        stroke: $defaultIconColor;
      }

      &--preview {
        stroke: $defaultIconColor;
        margin-left: 0;
      }
    }
  }

  &__cancelInfo {
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 0.5rem;

    &__text {
      margin-bottom: 1rem;
    }

    &__buttons {
      display: flex;
      align-items: center;

      &__button:first-child {
        margin-right: 1rem;
      }
    }
  }
}

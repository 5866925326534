@import "../../styles/theme.scss";

.PipelineChart {
  width: fit-content;
  margin: auto;

  .Card--outlined {
    overflow: auto;
  }

  &__container {
    width: fit-content;

    &__filters {
      background-color: transparent;
      width: fit-content;

      .Tab--selected {
        background-color: $grey500;
      }
    }

    &__chart {
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: radial-gradient(
        40% 30% at 50% 60%,
        rgba(252, 217, 0, 0.31) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem 0 1rem 3.5rem;

      &__legend {
        display: flex;
        flex-direction: row;
        row-gap: 1rem;
        column-gap: 1.5rem;
        flex-wrap: wrap;

        &__item {
          max-height: 2rem;
          max-width: 7rem;
          overflow: scroll;
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          .Typography {
            color: $grey700;
            text-transform: capitalize;
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 1rem;
      }
    }
  }
  &--small {
    display: flex;
    flex-direction: column;
    align-items: center;

    .PipelineChart__container__filters {
      background-color: $defaultBackground;
      margin-bottom: 1rem;
      .Tab--selected {
        background-color: $white;
      }
    }
    .PipelineChart__container__footer {
      padding: 0;
      &__actions {
        display: none;
      }
    }
    .PipelineChart__chart {
      padding: 0.5rem;
    }
  }
}

@import "../../styles/theme.scss";

.SurveyLaunchSteps {
  height: fit-content;
  .MuiCollapse-root {
    width: 100%;
  }
  .MuiStepLabel-label {
    font-size: 1rem;
  }

  .MuiStepIcon-root {
    color: $yellow200;
    width: 1.5rem;
    height: 1.5rem;
  }
  .MuiStepIcon-text {
    font-size: 0.8rem;
    fill: $grey900;
  }

  &__panel {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }
  &__actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
}

@import "../../styles/theme.scss";

.DiversityRatioOverview {
  padding: 2rem;
  text-align: center;

  &__title {
    margin-bottom: 2.5rem;
  }

  &__filters {
    max-width: fit-content;
    margin: auto;
  }

  &--container.DiversityRatioOverview--widget {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  &--widget {
    padding: 0;
    flex-grow: 1;

    .DiversityRatioOverview__noData {
      padding: 1.5rem 0;
    }

    .DiversityRatioOverview__filters {
      max-width: fit-content;
      margin: auto;
      margin-bottom: 0.5rem;
    }
  }

  &--widget.DiversityRatioOverview--loading {
    height: 100%;
  }

  &__loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

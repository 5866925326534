@import "../../styles/theme.scss";

.SurveyScoreCard {
  &__title {
    text-align: center;
  }
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
    &__dueDate {
      margin-left: 0.25rem;
    }
  }
  &__results {
    height: calc(100% - 2rem);
    .NoDataState--light {
      flex-direction: column;

      .NoDataState__text {
        align-items: center;
      }
    }

    &__score {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__chapters {
      flex-direction: column;
      justify-content: space-evenly;
      flex-wrap: wrap;

      @media (min-width: $md) {
        flex-direction: row;
      }

      &__item {
        display: flex;
        flex-direction: row;
        &__icon {
          width: 25px;
          align-self: flex-start;
          margin-left: -10px;
        }

        @media (max-width: $md) {
          justify-content: center;
        }
      }

      .ScoreArc__info__label {
        margin-right: -5px;
        margin-left: -5px;

        .Typography {
          font-size: 0.6rem;
        }
      }
    }
  }

  &__pendingResults__container {
    height: 285px;

    @media (max-width: $sm) {
      height: 200px;
    }
  }
  &__pendingResults {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    white-space: pre;
    justify-content: center;
    height: 100%;
    text-wrap: auto;
    &__img {
      height: 70%;
      max-height: 180px;
    }
    &__date {
      margin-top: 0.5rem;
    }
    &__link {
      margin-top: 1rem;
    }
  }
}

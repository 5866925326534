@import "../../styles/theme.scss";

.SurveyThankYou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  max-width: 70%;

  @media (max-width: $sm) {
    height: 100dvh !important;
  }

  &__title {
    margin-top: 4rem;
    margin-bottom: 2.5rem;
  }

  &__img {
    max-height: 350px;

    @media (max-width: $sm) {
      max-height: 250px;
    }
  }
}

@import "../../styles/theme.scss";

.SurveyPublicLink {
  &__link {
    margin: 1rem 0;
    border: 1px solid $grey400;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__icon {
      margin-right: 0.5rem;
    }

    &__button {
      min-width: 160px;
      margin-left: 0.5rem;
    }
  }
}

@import "../../../styles/theme.scss";

.Menu {
  .MuiPaper-root {
    overflow: visible;
    margin-top: 0.5rem;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12));
    border-radius: 16px;

    /* The arrow above the box */
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 50%;
      width: 10px;
      height: 10px;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
      background-color: $white;
    }

    .MuiList-root {
      padding: 0;
    }
  }

  &--arrowLeft {
    .MuiPaper-root {
      &::before {
        left: 30px;
      }
    }
  }

  &--arrowRight {
    .MuiPaper-root {
      &::before {
        right: 30px;
      }
    }
  }

  &__header {
    margin: 1rem;
    text-transform: uppercase;
    position: relative;
  }

  .Menu__item {
    padding: 0.5rem;
    min-width: 17rem;

    &--notClickable:hover {
      cursor: initial;
      background-color: $white;
    }

    &--clickable:hover {
      box-shadow: 0px 2px 20px 3px rgb(194 194 194 / 25%);
      transition: box-shadow 320ms ease;
      background-color: $white;
      border-radius: 16px;
      z-index: 1;

      .Menu__item__arrow {
        opacity: 1;
        transition: opacity 320ms ease;
      }
    }

    &--selected {
      font-weight: 700;
    }

    &:first-child {
      border-radius: $borderRadius $borderRadius 0 0;
      margin-top: 1px;
    }

    &:last-child {
      padding-bottom: 0.5rem;
      border-radius: 0 0 $borderRadius $borderRadius;
      background-color: $white;
    }

    &--onlyChild {
      &:last-child {
        border-radius: $borderRadius;
      }
    }

    &__icon {
      margin-right: 0.5rem;
      align-items: center;
      display: flex;
    }

    &__arrow {
      display: block;
      opacity: 0;
      margin-left: auto;
      margin-right: 1.5rem;
      transition: opacity 320ms ease;
      padding-left: 0.5rem;
    }
  }

  &--light {
    .Menu__item {
      min-width: fit-content;
      padding: 1rem;

      &:last-child {
        padding-bottom: 1.5rem;
      }

      &--onlyChild {
        &:last-child {
          padding-bottom: 1rem;
        }
      }

      &__icon {
        margin-right: 1rem;
      }
    }
    .Menu__item__arrow {
      display: none;
    }
  }
}

@import "../../styles/theme.scss";

.LevelPicker {
  &__select {
    border-radius: 16px;
    border: 1px solid $aldaStroke;
    padding: 1rem;
    width: 14rem;
    appearance: none; //hiding the default arrow
    background: url("./../ui/icons/NavArrow.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
  }
}

@import "../../styles/theme.scss";

.PipelineOverview {
  &--container {
    height: 100%;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  justify-content: flex-end;
  height: 100%;
  padding: 2rem;
  overflow: auto;

  &--widget {
    padding: 0;
  }

  &__title {
    margin-bottom: 3rem;
  }

  &__loading {
    &__recommendations {
      display: flex;
      gap: 1rem;
      padding-bottom: 2rem;
    }
    &__chart {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      &__bar {
        display: flex;
        flex-direction: column;
        width: 8rem;
        gap: 1rem;
      }
    }
  }
}

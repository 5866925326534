@import "../../styles/theme.scss";

.ActionPlanTodo {
  height: 100%;
  overflow-y: scroll;

  &__list {
    display: flex;
    width: fit-content;
    border-radius: $borderRadius;
    overflow: hidden;
    width: calc(100% - 2px);
    border: 1px solid $grey500;

    &__item {
      padding: 0.5rem 1rem;
      margin: 0;
      border-bottom: 1px solid $grey400;

      &:last-child {
        border-bottom: none;
      }

      &__infoButton {
        height: 24px;
        width: 24px;
        min-width: 24px;

        &.Button--primary:hover {
          background-color: $grey500;
        }
      }

      &__label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid $grey600;
      }
      &--completed {
        background-color: $yellow100;

        .ActionPlanTodo__list__item__icon {
          background-color: $yellow200;
          border-color: $yellow200;
        }
      }

      &__description {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__action {
          align-self: flex-end;
        }
      }
    }
  }
}

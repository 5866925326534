@import "../../../styles/theme.scss";

.ScaleTick {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  top: 6px;

  &--small {
    height: 8px;
    width: 8px;
    top: 8px;
  }
}

.ScaleAverageTick {
  position: absolute;
  top: 6px;

  .MuiTooltip-tooltip {
    background-color: red;
  }
}

/* Default z-index for tooltip is 1500*/
.ScaleAverageTooltip {
  z-index: 902;
}

/* Default z-index for tooltip is 1500*/
.ScaleTooltip {
  z-index: 900;
  max-width: 190px;

  &--single {
    z-index: 901;
  }

  &--front {
    z-index: 903;
  }

  &--hide {
    display: none;
  }

  &--editable {
    cursor: pointer;
  }
}

.ScaleTooltipData {
  padding: 0.5rem;
  text-align: center;

  &--small {
    .ScaleTooltipData__content {
      padding-top: 0;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  &--group {
    .ScaleTooltipData__content {
      text-align: left;
    }
  }

  &__title {
    border-bottom: 1px solid $defaultBorderColor;
    padding-bottom: 0.5rem;
  }

  &__content {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    position: relative;
    top: 3px;
    padding-right: 0.5rem;
    transition: opacity 320ms ease;
  }

  &__edit {
    position: relative;
    top: 3px;
    display: none;
    transition: opacity 320ms ease;
    padding-right: 0.5rem;
  }

  &:hover {
    .ScaleTooltipData__edit {
      display: initial;
    }
    .ScaleTooltipData__icon {
      display: none;
    }
  }
}

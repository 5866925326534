.DiversityRatioBreakdownComparison {
}
@import "../../styles/mixins.scss";
@import "../../styles/theme.scss";

.DiversityRatioBreakdownComparison {
  &__selector {
    margin-top: 1rem;
    width: 100%;

    .Select__input {
      width: 100%;
    }
  }

  &__legend {
    display: flex;
    justify-content: right;
    gap: 1rem;
    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__chart {
    position: relative;
    &__count {
      position: absolute;
      right: 0.75rem;
      top: 5px;
      z-index: 1;
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }

    // Hack to disable hover effect on legend
    button.highcharts-a11y-proxy-button,
    g.highcharts-legend-item {
      pointer-events: none;
    }
  }
}

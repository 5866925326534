@import "../../styles/theme.scss";

.SurveyNoticeCard {
  padding: 2rem;
  &__title {
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
  }
  &__text {
    text-align: justify;
  }
}

@import "../../styles/theme.scss";

.SurveySegment {
  text-align: center;
  width: 100%;

  &__title {
    margin: 0;
    padding-bottom: 1rem;
    @media (max-width: $sm) {
      padding: 0 0.5rem;
    }
  }

  &__illustration {
    width: 100%;
    border-radius: $borderRadius;
    padding-bottom: 0.5rem;

    &__img {
      max-height: 200px;
      @media (max-width: $sm) {
        max-height: 75px;
      }
    }
  }

  &__card {
    border: 1px solid $roseOrange;
    @media (max-width: $sm) {
      margin: 0 0.5rem;
    }

    &__title {
      color: $roseOrange;
      padding-bottom: 0.5rem;
    }

    &__text {
      white-space: pre-line;
      padding: 1rem;
      @media (max-width: $sm) {
        padding: 0 0.5rem;
      }
    }
  }
}

@import "../../styles/theme.scss";

.SurveyPreviewCard {
  &__card {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    width: 100%;
    gap: 1.5rem;
    img {
      max-width: 250px;
    }
  }

  &__button {
    justify-content: baseline;
  }
  .Button--secondary.Button--contained {
    background-color: white;
    border-color: white;
  }
}

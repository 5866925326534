@import "../../styles/theme.scss";

.UploadFile {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    gap: 0.5rem;
  }

  &__error {
    margin-top: 5px;
    text-align: center;
  }
}

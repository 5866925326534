.UpgradeSubscriptionForm {
  display: flex;
  flex-direction: column;

  &__actions {
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
  }
}

@import "../../../styles/theme.scss";
$animationTime: 240ms;

.Carousel {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;

    &__slide {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &__item {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transform: translateX(100%); /* Default off-screen */
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

        &--active-next,
        &--active-prev {
          opacity: 1;
          transform: translateX(0); /* Slide into view */
          position: relative;
        }

        &--exiting-next {
          opacity: 0;
          transform: translateX(-100%); /* Slide out to the left */
        }

        &--exiting-prev {
          opacity: 0;
          transform: translateX(100%); /* Slide out to the right */
        }

        &--incoming-next {
          opacity: 0;
          transform: translateX(100%); /* Start off-screen to the right */
        }

        &--incoming-prev {
          opacity: 0;
          transform: translateX(-100%); /* Start off-screen to the left */
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 40px;
      height: 40px;
      border-radius: 50%;
      background-color: transparent;
      transition: background-color $animationTime ease;
      cursor: pointer;
      z-index: 2;

      &:hover {
        background-color: $blue400;
        transition: background-color $animationTime ease;
      }

      svg {
        fill: $defaultText;
      }

      &--prev {
        margin-right: 1rem;
      }
      &--next {
        margin-left: 1rem;
      }
    }
  }

  &__indicators {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    &__item {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $grey400;
      margin-right: 2px;

      &--active {
        background-color: $blue400;
      }
    }
  }
}

@import "../../styles/theme.scss";

.WorkplaceCultureRail {
  display: flex;
  position: relative;

  &__icon {
    position: absolute;
    top: 2rem;

    &--up {
      right: 0;
    }

    @media (max-width: $md) {
      display: none;
    }
  }

  &__segment {
    width: 100%;

    .WorkplaceCultureRail__segment__color {
      height: 24px;

      &--1 {
        background-color: $blue600;
      }

      &--2 {
        background-color: $blue400;
      }

      &--3 {
        background-color: $blue300;
      }

      &--4 {
        background-color: $orange400;
      }

      &--5 {
        background-color: $roseOrange;
      }
    }

    &:nth-child(2) {
      .WorkplaceCultureRail__segment__color {
        border-bottom-left-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
      }
    }
    &:nth-last-child(2) {
      .WorkplaceCultureRail__segment__color {
        border-bottom-right-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
      }
    }

    &__value {
      margin-top: 0.5rem;
      text-align: right;
      margin-right: -3px;
      height: 1rem;
    }

    &__info {
      text-align: center;
    }
  }
}

@import "../../../styles/theme.scss";

.Button {
  min-width: 56px;
  height: 56px;
  text-transform: none;
  font-size: $fontSize16;
  line-height: 18px;
  border-radius: $borderRadius;
  padding: 1rem 2rem;
  box-shadow: none;
  transition: unset;

  &--backupFont {
    font-family: "Arial", sans-serif;
  }

  &--small {
    height: 40px;
    font-size: 0.9rem;
    padding: 1rem 1.5rem;
    border-radius: $smallBorderRadius;
  }

  &--xsmall {
    height: 25px;
    font-size: 0.7rem;
    padding: 0.5rem 1rem;
    border-radius: $smallBorderRadius;
  }

  &:hover {
    box-shadow: none;
  }

  &--primary {
    &:hover {
      background-color: $stoneBlueHover;
    }

    &.Button--outlined {
      &:hover {
        background-color: $stoneBlueHover;
        color: white;
        border-color: $stoneBlueHover;

        .MuiSvgIcon-root {
          fill: white;
        }
      }
    }
  }

  &--secondary {
    // hack so that buttons dont move on hover
    border: 1px solid $grey400;
    background-color: transparent;

    &.Button--contained {
      &:hover {
        background-color: $grey300;
      }

      background-color: $grey400;
      color: $grey900;
    }

    &.Button--outlined {
      border: 1px solid $grey400;

      &:hover {
        background-color: $grey300;
      }
    }

    &.Button--text {
      border: none;

      &:hover {
        border: none;
      }
    }
  }

  &--icon {
    border-radius: 50%;
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;

    &.Button--primary {
      .Icon {
        fill: $stoneBlue;
        stroke: $stoneBlue;
      }

      &:hover {
        .Icon {
          fill: white;
          stroke: white;
        }
      }
    }
  }

  &--icon.Button--xsmall {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &__loader {
    display: flex;
    padding-right: 0.5rem;
  }

  &--disabled {
    &.Button--contained:not(.Button--info) {
      background-color: $grey300;
      color: $grey700;
    }
    &.Button--contained.Button--info {
      background-color: $yellow200;
      opacity: 0.8;
    }
  }
}

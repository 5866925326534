@import "../../../styles/theme.scss";

$modalHeaderHeight: 36px;

.Modal {
  .MuiBackdrop-root {
    backdrop-filter: blur(4px);
    background: rgba(239, 239, 239, 0.4);
  }

  &--transparent {
    z-index: 0;
    .MuiBackdrop-root {
      backdrop-filter: blur(0);
      background: transparent;
    }
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    overflow-y: auto;

    max-width: 600px;
    max-height: 90%;

    @media (max-width: $sm) {
      width: 90%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      align-items: center;
      height: $modalHeaderHeight;

      &__title {
        flex-grow: 1;
        padding-right: 0.5rem;
      }
    }

    &__body {
      padding: 0 1.25rem 1.25rem 1.25rem;
      overflow-y: auto;
    }

    &:focus {
      outline: none;
    }

    &__icon {
      padding: 0 1.5rem;
    }

    &--light {
      display: flex;
      flex-direction: row-reverse;
      .Modal__container__action {
        position: relative;
        right: 0;
        margin: 1rem 1rem 0 0;
        z-index: 99;
      }
      .Modal__container__body {
        display: flex;
        padding: 1.5rem 0.5rem 1.5rem 1.5rem;
      }
    }

    &--backupFont {
      font-family: "Arial", sans-serif;
    }
  }

  &__background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

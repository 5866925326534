.RedArrowIcon {
  &--up {
    transform: rotate(270deg);
  }

  &--right {
    transform: rotate(180deg);
  }

  &--down {
    transform: rotate(90deg);
  }
}

@import "../../../styles/theme.scss";

.Card {
  border-radius: $borderRadius;
  box-shadow: none;
  padding: 1rem;

  &--inactive {
    border: 1px dashed $defaultBorderColor;
    background-color: $defaultBackground;
  }

  &--clickable {
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px rgb(194 194 194 / 25%);
    transition: box-shadow 240ms ease;
    &:hover {
      box-shadow: 0px 2px 20px 3px rgb(194 194 194 / 25%);
      transition: box-shadow 240ms ease;
    }
  }

  &--disabled {
    color: $secondaryColor;
    cursor: initial;
    &:hover {
      background-color: initial;
      transition: none;
      box-shadow: none;
    }
  }

  &--outlined {
    border-radius: 8px;
    border: 1px solid $aldaStroke;
  }

  &--square {
    border-radius: 0;
  }

  .MuiCardActionArea-root {
    height: 100%;
    border-radius: $borderRadius;

    .MuiCardActionArea-focusHighlight {
      opacity: 0;
    }
  }
}

.IntegrationSetup {
  .Modal__container {
    width: 40rem;
  }
  &__form {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
}

@import "../../../styles/theme.scss";

.Tabs {
  background-color: $defaultBackground;
  padding: 0.25rem;
  border-radius: 100px;

  &--small {
    min-height: 2rem;
    height: 2rem;
  }

  &__indicator {
    background-color: #fff;
    height: 100%;
    border-radius: 100px;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
}

.SurveyDemographics {
  margin-top: 4rem;

  &__card {
    margin-top: 1.5rem;

    &__info {
      display: flex;
      align-items: center;
      padding: 1rem 0;
    }

    &--empty {
      padding: 1rem 0.5rem;
    }
  }
}

@import "../../../styles/theme.scss";

.Tab {
  border-radius: 100px;
  text-transform: none;

  &--withIndicator {
    z-index: 1;
    opacity: 1;
    color: $grey700;
  }

  &--selected {
    background-color: white;
    opacity: 1;
  }
  &--selected.Tab--withIndicator {
    background-color: transparent;
    color: $grey900;
    transition: color 320ms ease 180ms;
  }
  &--small {
    min-height: inherit;
    height: 2rem;
  }

  &__label {
    display: flex;
    flex-direction: column;

    &__info {
      color: $grey700;
    }
  }
}

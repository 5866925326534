@import "./styles/theme.scss";

@font-face {
  font-family: "studiofeixen mono";
  src: url("./styles/fonts/StudioFeixenMono-Book.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "studiofeixen";
  src: url("./styles/fonts/StudioFeixenSans-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "studiofeixen";
  src: url("./styles/fonts/StudioFeixenSans-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "studiofeixen";
  src: url("./styles/fonts/StudioFeixenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lore";
  src: local("Lore"), url("./styles/fonts/LORE-Regular.woff2") format("woff2");
}

.MuiContainer-root {
  padding: 0 1.5rem;
  @media (min-width: 1536px) {
    max-width: $contentMaxWidth;
  }
}

.MuiSvgIcon-colorSecondary {
  fill: $iconGrey;
}

.Page {
  padding-bottom: $pageBottomPadding;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

// To make input a little less ugly when using 1pass
input[data-com-onepassword-filled="light"],
select[data-com-onepassword-filled="light"],
textarea[data-com-onepassword-filled="light"] {
  border-radius: $borderRadius;
}

a:-webkit-any-link {
  &:hover {
    color: inherit;
  }
  cursor: pointer;
  text-decoration: none;
}

@import "../../styles/theme.scss";

.SurveyEmailList {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 0 1rem;

  &__title {
    padding-bottom: 1rem;
  }

  &__buttonWrapper {
    padding-top: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-height: 11.875rem;
    overflow: auto;
    &__item {
      margin-right: 0.5rem;
      width: fit-content;
      border-radius: 40px;
      padding: 0.5rem 1.125rem;
      background-color: $green300;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;

      button {
        border: none;
        background-color: transparent;
        font-size: inherit;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@import "../../styles/theme.scss";

.SurveyDispatchAccordion {
  width: 100%;
  border-radius: $borderRadius;
  margin-bottom: 1rem;
  box-shadow: none;

  &__summary {
    width: 100%;
    padding: 0 1rem 0 0;
    .MuiAccordionSummary-content {
      margin: 0;
    }

    &--disabled {
      :hover:not(.Mui-disabled, .Button, .SurveyDispatchCard__card__actions__edit__icon) {
        cursor: default;
      }
    }
  }

  &__details {
    border-top: 1px solid $grey400;
    padding: 2rem;
    margin: 0 2rem;
    &__content {
      display: flex;
      align-items: center;

      &__scores {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: inherit;
      }

      &__insights {
        border-left: 1px solid $grey400;

        @media (max-width: $sm) {
          border-left: none;
          border-top: 1px solid $grey400;
          padding-top: 1rem;
        }
      }
    }
  }
}

// NOTE: if changing blue400, blue500, yellow200, red400, red500 or red700
// these colors are hardcoded in various places
// because of Material UI class issues and Highcharts
// they will also need to be changed

$white: #fff;
$black: #000000; // black (main)
$grey100: #f5f5f5; // cream (background)
$grey300: #f7f8fa; // grey 1 (text)
$grey400: #e5eaef; // grey 2 (background)
$grey500: #c8d1dc; // grey 3 (background)
$grey600: #b1b1b14d;
$grey700: #656f78; // grey 4 (background)
$grey800: #2a323a; // grey 5 (background)
$grey900: #1b1919; // coal (text)

$red100: #faeadc;
$red200: #f5e4e0;
$red500: #e37c5c; // Rose orange (main)

$orange400: #f2ab50; // missing name in figma
$purple300: #d9d4fc; // Extra soft purple
$purple800: #5a4a53; // Eggplant (graphs)
$green200: #e1fef6; // Extra soft mint
$green300: #d9fecf; // Extra soft green
$green400: #d8f4cb;
$green500: #8cba80; // Olivine (graphs)

$blue100: #f3f3f7;
$blue200: #eaf0f1; // soft blue (main)
$blue300: #daf4f4; // vista blue (graphs)
$blue400: #9ab6bd; // damp cyan (graphs)
$blue500: #3f7fbe; // dark vista blue (graphs)
$blue600: #111341; // penn blue (graphs)
$blue700: #5d768c; // stone blue (main)
$blue800: #435869; // darker stone blue (text)

$yellow100: #f1f3e6; // lite lime
$yellow200: #e2ea5b; // key lime (main) - info
$yellow300: #fffda0; // extra soft yellow

$defaultBackground: $grey100;
$secondaryBackground: #fff;

$roseOrange: $red500;
$stoneBlue: $blue700;
$stoneBlueHover: $blue400;
$secondaryColor: $grey700;
$infoColor: $blue400;
$aldaStroke: $grey600;

$defaultText: $grey900;
$defaultBorderColor: $grey400;
$defaultIconColor: $grey900;

$iconGrey: $grey500;

// 1rem = 16px
$fontSize18: 1.2rem;
$fontSize16: 1rem;
$fontSize14: 0.8rem;

/* Breakpoints from MUI */
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

$contentMaxWidth: 2000px;

$borderRadius: 16px;
$smallBorderRadius: 28px;
$boxShadow: 0px 8px 64px 6.24px rgba(139, 139, 139, 0.25);

// UI specific variables
$questionsMaxWidth: 450px;
$headerHeight: 65px;
$footerHeight: 101px;
$mobileFooterHeight: 60px;
$dashboardWidgetHeight: 520px;
$dashboardWidgetHeaderHeight: 88px;
$comingsoonWidgetHeight: 312px;
$trainingModuleHeight: 360px;
$pageTopPadding: 1.5rem;
$pageBottomPadding: 5rem;
$breadcrumbsHeight: 50px;

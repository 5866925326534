@import "../../styles/theme.scss";

.CustomQuestionsModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__table {
    margin-top: 1rem;
    &__header {
      font-weight: bold;
      border-bottom: 1px solid $defaultBorderColor;
    }
    &__row {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .Button {
    margin-top: 1rem;
    max-width: 200px;
  }
}

@import "../../styles/theme.scss";

.SurveyEmailInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &__inputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
    input {
      height: 0.4375rem;
    }
  }

  .Button--small {
    border-radius: 16px;
  }
}
